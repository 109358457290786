import React from 'react';

const NottingHillCarnival = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 468 32">
    <path d="M.2 31V1.9H9v1h.6v1h.5V5h.5v1h.5v1h.5v1.1h.6v1h.5v1.1h.5v1h.5v1h.5v1.1h.6v1h.5v1.1h.5v1h.5v1h.5v1.1h.6v1h.5v1.1h.5v1h.5V2h6.3V31h-8.9v-1h-.5v-1h-.5v-1.1h-.5v-1h-.6v-1.1h-.5v-1h-.5v-1h-.5v-1.1h-.5v-1h-.6v-1.1h-.5v-1h-.5v-1h-.5v-1.1h-.5v-1H9v-1.1h-.5v-1H8v-1h-.5v-1.1H7v-1h-.6V31H.2z"/>
    <path d="M28.4 22.7V10.2h.5V8.6h.5v-1h.5v-1h.6V6h.5v-.5h.5V5h.5v-.5h.5V4h.6v-.6h.5V3h1v-.5h1v-.5h1.6v-.5h9.4v.5H48v.5h1v.5h1.1v.5h.5V4h.6v.5h.5V5h.5v.5h.5V6h.5v.6h.6v1h.5v1h.5v1.6h.5v12.5H55v1.5h-.5v1h-.5v1.1h-.6v.5h-.5v.6h-.5v.5h-.5v.5h-.5v.5h-.6v.5h-.5v.6h-1v.5h-1v.5h-1.6v.5h-9.4V31h-1.5v-.5h-1V30h-1.1v-.6H33V29h-.6v-.5H32v-.5h-.5v-.5H31v-.6h-.5v-.5h-.6v-1h-.5v-1H29v-1.6h-.5zm6.2-1.6h.5v1h.6v1.1h.5v.5h.5v.5h1v.6h1v.5H45v-.5h1v-.6h1.1v-.5h.5v-.5h.5v-1h.6v-1h.5v-9.4h-.5v-1H48V9.6h-.5v-.5h-.5v-.6h-1v-.5h-1v-.5h-6.3v.5h-1v.5h-1v.6h-.6v.5h-.5v1H35v1h-.5v9.4z"/>
    <path d="M56.4 8.1V2h23V8H71V31h-6.2V8.1h-8.4z"/>
    <path d="M80.1 8.1V2H103V8h-8.3V31h-6.3V8.1h-8.3z"/>
    <path d="M104.6 31V1.9h6.3V31h-6.3z"/>
    <path d="M115.2 31V1.9h8.9v1h.5v1h.5V5h.5v1h.6v1h.5v1.1h.5v1h.5v1.1h.5v1h.6v1h.5v1.1h.5v1h.5v1.1h.5v1h.6v1h.5v1.1h.5v1h.5v1.1h.5v1h.6V2h6.2V31h-8.8v-1h-.6v-1h-.5v-1.1h-.5v-1h-.5v-1.1h-.5v-1h-.6v-1h-.5v-1.1h-.5v-1h-.5v-1.1h-.5v-1h-.6v-1h-.5v-1.1h-.5v-1h-.5v-1.1h-.5v-1h-.6v-1h-.5v-1.1h-.5v-1h-.5V31h-6.3z"/>
    <path d="M143.4 23.2V9.7h.5V8h.6v-1h.5V6h.5v-.6h.5V5h.5v-.5h.6V4h.5v-.6h.5V3h1v-.5h1v-.5h1.6v-.5h10v.5h1v.5h1v.5h1v.5h.6V4h.5v.5h.5V5h.5v.5h.5v1h.6v1.1h.5v1.6h.5v3h-6.2v-1.5h-.6v-1h-.5v-.5h-.5v-.6h-.5v-.5h-1v-.5h-5.8v.5h-1v.5h-1v.6h-.6v.5h-.5v1h-.5v1h-.5v9.4h.5v1h.5v1.1h.5v.5h.5v.5h1v.6h1.1v.5h6.3v-.5h1v-.6h.5v-.5h.5v-.5h.6v-1.6h-6.3v-6.2H169V31h-5.2v-1.6h-.5v.6h-.5v.5h-1v.5H160v.5h-8.4V31h-1.5v-.5h-1V30h-1v-.6h-.6V29h-.5v-.5h-.6v-.5h-.5v-.5h-.5v-.6h-.5v-1h-.5v-1h-.6v-1.6h-.5z"/>
    <path d="M181.1 31V1.9h6.2v11.4h12.5V2h6.3V31h-6.3V19.6h-12.5V31h-6.2z"/>
    <path d="M210.5 31V1.9h6.2V31h-6.2z"/>
    <path d="M221.1 31V1.9h6.2v22.9H242V31h-20.8z"/>
    <path d="M244.3 31V1.9h6.2v22.9h14.6V31h-20.8z"/>
    <path d="M299.2 12c0-2-2.6-1.3-2.6-3.2 0-2 3.8-2 3.8-5.9 0-1.5-1-2.5-2.4-2.5-2.7 0-6.7 3.4-9.7 3.4-2.7 0-7-3.4-9.8-3.4-1.4 0-2.4 1-2.4 2.5 0 3.8 4.3 5 4.3 9 0 4.7-5.9 4.2-5.9 10.6 0 6 5 9.2 13.5 9.2 7.8 0 12-3 12-9.6 0-1.8-1.5-2.8-3-2.8a3 3 0 00-3.2 3.2c0 1.7 1.3 2.7 1.3 3.8 0 1.1-1.5 2-7 2-5.1 0-8.5-1.9-8.5-5.7 0-4 5.6-6.8 5.6-9 0-1.8-2.4-2.7-2.4-4.3 0-2.6 2.4-4 5.4-4 3.3 0 5.6 1.5 5.6 4 0 2.8 1.5 4.6 3.4 4.6 1.2 0 2-.7 2-1.8z"/>
    <path d="M301.8 31v-1.6h.6V28h.5v-1.6h.5v-1.5h.5v-1.6h.5v-1.6h.6v-1.5h.5v-1.6h.5V17h.5v-1.6h.5v-1.6h.6v-1.5h.5v-1.6h.5V9.2h.5V7.6h.5V6h.6V4.5h.5V2.9h.5V2h8.8v1h.6v1.6h.5V6h.5v1.6h.5v1.6h.5v1.5h.6v1.6h.5v1.5h.5v1.6h.5V17h.5v1.5h.6v1.6h.5v1.5h.5v1.6h.5v1.6h.5v1.5h.6V28h.5v1.5h.5V31h-6.8v-1.6h-.5V28h-.5v-1.6h-12V28h-.5v1.5h-.5V31h-6.8zm10-11h7.7v-1h-.5v-1.5h-.5v-1.6h-.5v-1.5h-.6v-1.6h-.5v-1.6h-.5V9.7h-.5V8h-.5v1.6h-.6v1.5h-.5v1.6h-.5v1.6h-.5v1.5h-.5v1.6h-.6V19h-.5v1z"/>
    <path d="M331.4 31V1.9h16.7v.5h1.5v.5h1v.5h.6V4h.5v.5h.5V5h.5v.5h.6v1h.5v1.1h.5v5.7h-.5v1h-.5v1.1h-.6v.5h-.5v.5h-.5v.6h.5v.5h.5v1h.6v1h.5V31h-6.3v-9.4h-.5v-1h-.5v-.5h-.5v-.5h-8.3V31h-6.3zm6.3-17.7h9.3v-.5h.5v-.5h.6V9.2h-.6v-.6h-.5v-.5h-9.3v5.2z"/>
    <path d="M357.9 31V1.9h8.8v1h.6v1h.5V5h.5v1h.5v1h.5v1.1h.6v1h.5v1.1h.5v1h.5v1h.5v1.1h.6v1h.5v1.1h.5v1h.5v1h.5v1.1h.6v1h.5v1.1h.5v1h.5V2h6.3V31H374v-1h-.5v-1h-.5v-1.1h-.5v-1h-.6v-1.1h-.5v-1h-.5v-1h-.5v-1.1h-.5v-1h-.6v-1.1h-.5v-1h-.5v-1h-.5v-1.1h-.5v-1h-.6v-1.1h-.5v-1h-.5v-1h-.5v-1.1h-.5v-1h-.6V31H358z"/>
    <path d="M387.3 31V1.9h6.2V31h-6.2z"/>
    <path d="M411.7 31l3-10.2c2.3-8 6-18.2 7.3-18.8h-9c1.6 1.6 2.1 4.2 2.1 7.3 0 5-1.4 11.3-2.8 16.2l-5.8-18.3c-.4-1.3-.8-2.7-.8-3.8 0-.6.2-1 .5-1.4h-10.3c1.3.6 2 3 2.7 5.2L406 31h5.7z"/>
    <path d="M417.3 31v-1.6h.5V28h.5v-1.6h.5v-1.5h.6v-1.6h.5v-1.6h.5v-1.5h.5v-1.6h.5V17h.6v-1.6h.5v-1.6h.5v-1.5h.5v-1.6h.5V9.2h.6V7.6h.5V6h.5V4.5h.5V2.9h.5V2h8.9v1h.5v1.6h.5V6h.5v1.6h.6v1.6h.5v1.5h.5v1.6h.5v1.5h.5v1.6h.5V17h.6v1.5h.5v1.6h.5v1.5h.5v1.6h.6v1.6h.5v1.5h.5V28h.5v1.5h.5V31h-6.7v-1.6h-.6V28h-.5v-1.6h-12V28h-.4v1.5h-.6V31h-6.7zm9.8-11h7.8v-1h-.5v-1.5h-.5v-1.6h-.5v-1.5h-.5v-1.6h-.5v-1.6h-.6V9.7h-.5V8h-.5v1.6h-.5v1.5h-.6v1.6h-.5v1.6h-.5v1.5h-.5v1.6h-.5V19h-.6v1z"/>
    <path d="M446.8 31V1.9h6.3v22.9h14.5V31h-20.8z"/>
  </svg>
);

export default NottingHillCarnival;
