import DynamicHeading, { HEADING_OPTIONS } from '../dynamic-heading';

import HeadlineArticles from './articles';
import HeadlinePodcasts from './podcasts';
import HeadlineSoundsystems from './soundsystems';
import HeadlineTakeovers from './takeovers';
import PropTypes from 'prop-types';
import React from 'react';

export const HEADLINE_TYPES = {
  ARTICLES: 'articles',
  PODCASTS: 'podcasts',
  SOUNDSYSTEMS: 'soundsystems',
  TAKEOVERS: 'takeovers',
};

function getHeadline(key) {
  switch (key) {
    case HEADLINE_TYPES.ARTICLES:
      return {
        heading: 'Articles',
        graphic: <HeadlineArticles aria-hidden="true" />,
      };
    case HEADLINE_TYPES.PODCASTS:
      return {
        heading: 'Podcasts',
        graphic: <HeadlinePodcasts aria-hidden="true" />,
      };
    case HEADLINE_TYPES.SOUNDSYSTEMS:
      return {
        heading: 'Soundsystems',
        graphic: <HeadlineSoundsystems aria-hidden="true" />,
      };
    case HEADLINE_TYPES.TAKEOVERS:
      return {
        heading: 'Takeovers',
        graphic: <HeadlineTakeovers aria-hidden="true" />,
      };
    default:
      return { heading: '', graphic: null };
  }
}

const Headline = props => {
  const { type, headingLevel, ...otherProps } = props;
  const headlineData = getHeadline(type);

  return (
    <div {...otherProps}>
      {props.children}
      <DynamicHeading tag={headingLevel} className="visually-hidden">
        {headlineData.heading}
      </DynamicHeading>
      {headlineData.graphic}
    </div>
  );
};

Headline.propTypes = {
  type: PropTypes.string.isRequired,
  headingLevel: PropTypes.string,
};

Headline.defaultProps = {
  headingLevel: HEADING_OPTIONS.H1,
};

export default Headline;
