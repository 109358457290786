  import React, { useContext, useEffect } from 'react';
import 'css/index.css';

import ThemeContext, { THEMES } from 'common/context.theme';
import GroupHomeSpotlight from 'components/group-home-spotlight';
import HomeIntro from 'components/home-intro';
import AppContext from 'common/context.app';
  import SEO from '../components/seo';

const IndexPage = () => {
  const { setCurrentPage } = useContext(AppContext);
  const { setTheme } = useContext(ThemeContext);
  useEffect(() => {
    setTheme(THEMES.dark);
    setCurrentPage('/');
  }, [setTheme, setCurrentPage]);

  return (
    <div>
      <SEO title={'Home'}/>
      <h1 className="visually-hidden">Carnival Sounds</h1>
      <HomeIntro />
      <GroupHomeSpotlight />
    </div>
  );
};

export default IndexPage;
