import React from 'react';
const HeadlineArticles = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 360 73"
  >
    <path
      class="st0"
      d="M9.1,9.2V3.1h13.5v6.1h1.2v4.9H25V19h1.2v4.9h1.2v4.9h1.2v4.9h1.3v4.9h1.2v4.9h1.2v4.9h1.2v-4.9h1.2v-4.9h1.2
	v-4.9h1.2v-4.9h1.2v-4.9h1.2V19h1.2v-4.9h1.2V9.2h1.2V5.5h1.2V3h13.5v2.5H59v3.7h1.2v4.9h1.2V19h1.2v4.9h1.2v4.9H65v4.9h1.2v4.9h1.2
	v4.9h1.2v4.9h1.2v-4.9H71v-4.9h1.2v-4.9h1.2v-4.9h1.2v-4.9h1.2V19H77v-4.9h1.3V9.2h1.2V3.1H93v6.1h-1.2v4.9h-1.2V19H90v4.9h-1.2v4.9
	h-1.2v4.9h-1.2v4.9h-1.2v4.9H84v4.9h-1.2v4.9h-1.2v4.9h-1.2v4.9H79V68h-1.3v3.7H61.8V68h-1.2v-4.9h-1.2v-4.9h-1.2v-4.9H57v-4.9h-1.2
	v-4.9h-1.2v-4.9h-1.2v-4.9H52v-4.9h-1.2v4.9h-1.2v4.9h-1.2v4.9h-1.2v4.9H46v4.9h-1.2v4.9h-1.2v4.9h-1.2V68H41v3.7H25.1V68h-1.2v-4.9
	h-1.2v-4.9h-1.2v-4.9h-1.2v-4.9h-1.2v-4.9h-1.2v-4.9h-1.2v-4.9h-1.2v-4.9H14v-4.9h-1.2V19h-1.2v-4.9h-1.2V9.2L9.1,9.2L9.1,9.2z"
    />
    <path
      class="st0"
      d="M172.2,71.7V3.1h39.3v1.2h3.6v1.2h2.4v1.2h1.2v1.2h1.2v1.2h1.2v1.2h1.2v1.2h1.2V14h1.2v2.4h1.2v13.5h-1.2v2.5
	h-1.2v2.4h-1.2v1.3h-1.2v1.2H220v1.2h1.2v1.2h1.2v2.4h1.2v2.5h1.2v26.9h-14.7v-22H209V47h-1.2v-1.2h-1.2v-1.2h-19.7v27L172.2,71.7
	L172.2,71.7z M186.9,30H209v-1.2h1.2v-1.2h1.2v-7.4h-1.2V19H209v-1.2h-22.1V30z"
    />
    <path
      class="st0"
      d="M233.6,71.7V3.1h36.8v1.2h3.6v1.2h2.5v1.2h2.5v1.2h1.2v1.2h1.2v1.2h1.2v1.2h1.2v1.2h1.2v1.2h1.2v1.2h1.2v2.4
	h1.2v2.4h1.3v3.7h1.2v4.9h1.2v17.2h-1.2v4.9H290v3.7h-1.2v2.4h-1.3v2.5h-1.2v1.2H285v1.2h-1.2v1.2h-1.2V64h-1.2v1.2h-1.2v1.2H279
	v1.2h-2.5v1.2H274V70h-3.7v1.2L233.6,71.7z M248.3,57h15.9v-1.2h3.7v-1.2h2.5v-1.3h1.2v-1.2h1.2v-1.2h1.2v-1.2h1.2v-2.4h1.2v-3.7
	h1.2V31.3h-1.2v-3.7h-1.2v-2.4H274V24h-1.2v-1.2h-1.2v-1.2h-1.2v-1.3H268v-1.2h-3.7v-1.2h-15.9V57H248.3z"
    />
    <path
      class="st0"
      d="M305.1,49.8c2.7,0,4.8,1.3,4.8,4.1c0,3-0.7,6.5-0.7,9.1c0,2.4,0.6,4,2.6,4c5.4,0,2.8-21.7,12.1-21.7
	c5.4,0,5,15.1,9.6,15.1c1.8-0.1,3.3-1.7,3.2-3.5c0-5-5-4.8-5-11.4s9.5-4.2,9.5-9.8c0-2.7-1.1-4.5-6.6-4.5
	c-8.3,0-17.9,12.3-24.8,12.3c-5,0-6.4-1.9-6.4-5.4c0-4.3,7.6-9.9,7.6-15.6c0-5.4-12.6-2.4-12.6-12.7c0-8,15.2-9.8,29.5-9.8
	c7.5,0,17.3,2.2,17.3,10.5c0,4.9-3.6,9.8-8.6,9.8c-5.3,0-7.1-2.7-7.1-6c0-2.7,3.2-4.7,3.2-6.6c0-2.1-2.2-2.8-9.2-2.8
	c-9.3,0-13.3,2.1-13.3,3.6c0,3.3,9.6,3.5,9.6,8.8c0,5.8-5.6,8.3-5.6,12.7c0,2.2,0.9,2.8,2.5,2.8c5.4,0,13.4-10.2,25.1-10.2
	c5.4,0,7.9,2.6,7.9,7.2c0,7.8-6.8,8.4-6.8,13.6c0,6.5,6.9,5.8,6.9,18.6c0,5.3-3,11.1-10,11.1c-11.7,0-9.3-12.4-15-12.4
	c-8.4,0-5.7,12.2-16.3,12.2c-8,0-13.7-4.3-13.7-12.4C294.9,54.6,299.4,50,305.1,49.8z"
    />
    <path
      class="st0"
      d="M165.5,37.3c0,22.1-15.2,35.6-35.8,35.6c-20.4,0-35.6-13.6-35.6-35.6c0-21.9,15.2-35.5,35.6-35.5
	C150.3,1.8,165.5,15.3,165.5,37.3z M140.5,37.3c0-21.5-2.7-33.4-10.8-33.4s-10.6,11.9-10.6,33.4c0,21.7,2.5,33.7,10.6,33.7
	C137.9,71,140.5,58.9,140.5,37.3z"
    />
  </svg>
);

export default HeadlineArticles;
