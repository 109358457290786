// import { Badge2 } from '../svgs/badges';
import NottingHillCarnival from 'components/svgs/notting-hill-carnival';
import React from 'react';
import Styles from './home-intro.module.css';

const HomeIntro = props => {
  return (
    <div
      className={`nhc-container-left nhc-container-left--mobile-only nhc-container-right ${Styles.homeIntro}`}
    >
      <p className={Styles.intro}>
        From the streets to your speakers —&nbsp;
        <span className="visually-hidden">Notting Hill Carnival</span>
        <span className={Styles.NottingHill} aria-hidden="true">
          <NottingHillCarnival />
        </span>
        &nbsp;and&nbsp;
        <span className={Styles.Spotify}>Spotify</span>
        &nbsp;bring <br className={Styles.introBreak} />
        the party online
      </p>

      {/* <div className={Styles.hashtagWrapper}>
        <div className={Styles.hashtag}>
          <div className={Styles.hashtagBadge}>
            <div>
              <Badge2 />
            </div>
          </div>
          <p className={Styles.hashtagText}>#NHCAtHome</p>
        </div>
      </div> */}
    </div>
  );
};
export default HomeIntro;
