import Headline, { HEADLINE_TYPES } from 'components/headline';
import { Podcasts, SoundSystems } from 'components/svgs/home-headlines';

// import ArticlesImage from 'images/home/articles.jpg';
// import ArticlesShape from 'images/shapes/articles.svg';
import { HEADING_OPTIONS } from 'components/dynamic-heading';
import Link from 'gatsby-link';
import PodcastsImage from 'images/home/podcasts.jpg';
import PodcastsShape from 'images/shapes/podcasts.svg';
import React from 'react';
import SoundSystemsImage from 'images/home/soundsystems.jpg';
import SoundSystemsShape from 'images/shapes/soundsystemshome.svg';
import Styles from './group-home-spotlight.module.css';
import TakeoversImage from 'images/home/takeovers.jpg';
import TakeoversShape from 'images/shapes/takeovers-alternative.svg';
import { sendTrackingEvent } from '../../common/sendTrackingEvent';

const SpotlightSoundsystems = () => (
  <Link
    className={Styles.link}
    to="/sound-systems/"
    onClick={() => sendTrackingEvent('homepage', 'click', 'Sound Systems')}
  >
    <img
      className={Styles.image}
      src={SoundSystemsImage}
      aria-hidden="true"
      loading="lazy"
      alt=""
    />

    <img src={SoundSystemsShape} className={Styles.badge} alt="" />
    <div className={`${Styles.itemInner} ${Styles.itemInnerSoundsystems}`}>
      <h2 className="visually-hidden">Soundsystems</h2>
      <SoundSystems className={Styles.heading} />
    </div>
    <p className={Styles.tagline}>
      PLAYLISTS TO MATCH
      <br />
      YOUR RIDDIM ON ROAD.
    </p>
  </Link>
);

const SpotlightTakeovers = () => (
  <Link
    className={Styles.link}
    to="/playlist-takeovers/"
    onClick={() => sendTrackingEvent('homepage', 'click', 'Takeovers')}
  >
    <img
      className={Styles.image}
      src={TakeoversImage}
      aria-hidden="true"
      loading="lazy"
      alt=""
    />

    <img
      src={TakeoversShape}
      className={`${Styles.badge} ${Styles.badgeTakeovers}`}
      alt=""
    />

    <div className={`${Styles.itemInner} ${Styles.itemInnerTakeovers}`}>
      <Headline
        className={Styles.heading}
        type={HEADLINE_TYPES.TAKEOVERS}
        headingLevel={HEADING_OPTIONS.H2}
      />
    </div>
    <p className={Styles.tagline}>
      CURATED BY YOUR
      <br />
      FAVOURITE DJS.
    </p>
  </Link>
);

// const SpotlightArticles = () => (
//   <Link
//     className={Styles.link}
//     to="/words/"
//     onClick={() => sendTrackingEvent('homepage', 'click', 'Articles')}
//   >
//     <img
//       className={Styles.image}
//       src={ArticlesImage}
//       aria-hidden="true"
//       loading="lazy"
//       alt=""
//     />
//     <img
//       src={ArticlesShape}
//       className={`${Styles.badge} ${Styles.badgeArticles}`}
//       alt=""
//     />
//     <div className={`${Styles.itemInner} ${Styles.itemInnerArticles}`}>
//       <Headline
//         className={Styles.heading}
//         type={HEADLINE_TYPES.ARTICLES}
//         headingLevel={HEADING_OPTIONS.H2}
//       />
//     </div>
//     <p className={Styles.tagline}>
//       A SELECTION OF POEMS,
//       <br />
//       ACCOUNTS AND STORIES.
//     </p>
//   </Link>
// );

const SpotlightPodcasts = () => (
  <Link
    className={Styles.link}
    to="/podcasts/"
    onClick={() => sendTrackingEvent('homepage', 'click', 'Podcasts')}
  >
    <img
      className={Styles.image}
      src={PodcastsImage}
      aria-hidden="true"
      loading="lazy"
      alt=""
    />

    <img
      src={PodcastsShape}
      className={`${Styles.badge} ${Styles.badgePodcasts}`}
      alt=""
    />
    <div className={`${Styles.itemInner} ${Styles.itemInnerPodcasts}`}>
      <h2 className="visually-hidden">Podcasts</h2>
      <Podcasts className={Styles.heading} />
    </div>
    <p className={Styles.tagline}>
      CARNIVAL'S VIBRANT
      <br />
      LEGACY, ONLINE.
    </p>
  </Link>
);

const GroupHomeSpotlight = () => {
  return (
    <div className={Styles.groupHomeSpotlight}>
      <SpotlightSoundsystems />
      <SpotlightTakeovers />
      {/* <SpotlightArticles /> */}
      <SpotlightPodcasts />
    </div>
  );
};

GroupHomeSpotlight.defaultProps = {};

GroupHomeSpotlight.propTypes = {};

export default GroupHomeSpotlight;
